<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Foto </strong><small> Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateFoto">
            <CRow>
              <CCol sm="12">
                <CInput
                  v-model="jdl_gallery"
                  label="Judul Foto"
                  placeholder="Input Judul Foto"
                />
              </CCol>

              <CCol sm="6">
                <div class="form-group">
                <label>Album Foto</label>
                  <select class="form-control" v-model="album">
                    <option value="" disabled selected>Pilih Album</option>
                    <option v-for="r in albums" :value="r.id_album">{{r.jdl_album}} </option>
                  </select>
                </div>
              </CCol>

              <CCol sm="6">
                <div class="form-group">
                  <label>File Foto </label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange2($event)" id="inputGroupFile02"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                  </div>
                </div> 
              </CCol>

              <CCol sm="12">
                <CTextarea
                  v-model="caption_gallery"
                  label="Caption Foto"
                  placeholder="Caption Foto"
                /> 
              </CCol>

              <CCol sm="6">
                <CInput
                  v-model="credit_gallery"
                  label="Credit Foto"
                  placeholder="Credit Foto"
                />
              </CCol>

            </CRow>        
                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/foto">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>   
</template>
<script>

import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      jdl_gallery : "",
      jdl_album : "",
      gbr_gallery : "",
      gbr_gallery_text : "",
      caption_gallery : "",
      credit_gallery : "",
      album : "",
      placeholder : "Pilih File",
      albums : [], // Must be an array reference!
      selected : [], // Must be an array reference!
      show: true,
      modalError:false,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.loadData();
  this.showFoto();
  },
  methods: {

    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.gbr_gallery_text = fileData.name;
      this.gbr_gallery = fileData;
      this.placeholder = fileData.name;
      // alert(fileData.name);
    },

    loadData: function(event){
      
      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"albums")
        .then((response) => {
              this.albums=response.data;
        });
    },

    updateFoto: function(event){
      
      const formData = new FormData()
      
      formData.append('id_gallery', this.$route.params.id_gallery)
      
      formData.append('jdl_gallery', this.jdl_gallery)
      formData.append('id_album', this.album)
      formData.append('caption_gallery', this.caption_gallery)
      formData.append('credit_gallery', this.credit_gallery)

      if(this.gbr_gallery_text){
        formData.append('gbr_gallery', this.gbr_gallery, this.gbr_gallery.name)     
        formData.append('gbr_gallery_text', this.gbr_gallery.name)
      }

      axios.post(process.env.VUE_APP_BASE_URL+"foto/"+this.$route.params.id_gallery, formData )
        .then((response) => {

            if(response.data.status==='success'){
              this.$router.push('/foto');
            }
            else{
              this.modalError = true;
            }  
            
        })
    },

    showFoto: function(event){
      // Simple POST request with a JSON body using axios
      const foto = { 
                    jdl_gallery:this.jdl_gallery,
                    jdl_album: this.jdl_album, 
                    // gbr_gallery: this.gbr_gallery, 
                    caption_gallery: this.caption_gallery, 
                    credit_gallery: this.credit_gallery
                    };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"foto/"+this.$route.params.id_gallery)
        .then((response) => {
            
              const data = response.data.data;
              this.jdl_gallery=data.jdl_gallery;
              this.album=data.id_album;
              // this.gbr_gallery=data.gbr_gallery;
              this.caption_gallery=data.caption_gallery;
              this.credit_gallery=data.credit_gallery;
              this.placeholder=data.gbr_gallery;
            
        })
    }
  }
}
</script>